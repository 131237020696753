import type { CreateAxiosDefaults } from 'axios';

import axios from 'axios';

import {
  registerApiErrorParserResponseInterceptor,
  registerOptionalTokenInterceptor,
} from '@/adapters/api/axios/utils';

// NOTE: set 70 secs due to BIG QUERY sometime runs longer than 30s
const TIMEOUT_MS = 1000 * 70;

const AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.API,
  timeout: TIMEOUT_MS,
  withCredentials: false,
};

const axiosInstance = axios.create(AXIOS_CONFIG);

// NOTE!: the order of interceptor IS IMPORTANT!
registerOptionalTokenInterceptor(axiosInstance);
registerApiErrorParserResponseInterceptor(axiosInstance);

export default axiosInstance;
