import type { CustomColumnsConfigMap } from '@/models/common';

import invariant from 'tiny-invariant';

import { COLUMN_ID_REGEX, FX_COLUMN_ID_REGEX } from './shared/constants';
import { AGG_FN_TYPE, ANALYTICAL_FN_TYPE } from './shared/ohm-grammar/constant';
import { checkContainFns, checkFnGrammar } from './shared/ohm-grammar/utils';

export function isAggFx(
  fx: string | null | undefined,
  columnsConfig: CustomColumnsConfigMap,
) {
  if (!fx) return false;

  const doesContainFns = checkContainFns(fx, AGG_FN_TYPE);

  // recursively check if this fx contains any AGG FX columns
  const refMap = createFxReferenceMap({
    targetFx: fx,
    columnsConfig,
  });
  const hasRefOtherFormulas = !!Object.keys(refMap).length;
  let doesContainsAggFxColumn = false;
  if (hasRefOtherFormulas) {
    doesContainsAggFxColumn = Object.values(refMap).some((fxContent) => {
      return isAggFx(fxContent, columnsConfig);
    });
  }
  return doesContainFns || doesContainsAggFxColumn;
}

export function isAnalyticalFx(
  fx: string | null | undefined,
  columnsConfig: CustomColumnsConfigMap,
) {
  if (!fx) return false;

  const doesContainFns = checkContainFns(fx, ANALYTICAL_FN_TYPE);

  if (doesContainFns) return true;

  // recursively check if this fx contains any ANALYTICAL FX columns
  const refMap = createFxReferenceMap({
    targetFx: fx,
    columnsConfig,
  });

  const hasRefOtherFormulas = !!Object.keys(refMap).length;

  let doesContainsAnalyticalFxColumn = false;

  if (hasRefOtherFormulas) {
    doesContainsAnalyticalFxColumn = Object.values(refMap).some((fxContent) => {
      return isAnalyticalFx(fxContent, columnsConfig);
    });
  }

  return doesContainsAnalyticalFxColumn;
}

export function wrapSumAnalyticalFx(fx: string): string {
  if (!fx) return fx;

  const doesContainFns = checkContainFns(fx, ANALYTICAL_FN_TYPE);

  if (doesContainFns)
    return fx.replaceAll(
      new RegExp(
        `window_sum\\(\\{(${COLUMN_ID_REGEX}|(${FX_COLUMN_ID_REGEX}))\\}\\)`,
        'g',
      ),
      'window_sum(sum({$1}))',
    );

  return fx;
}

export function isFxColumnId(colId: string) {
  return new RegExp(`^(${FX_COLUMN_ID_REGEX})$`, 'i').test(colId);
}

export function isFxValid(fx: string): boolean {
  // NOTE: skip validation when empty
  if (fx === '') return false;

  return checkFnGrammar(fx).succeeded();
}

export function toFxColumnId(columnId: string): string {
  const regEx = new RegExp(FX_COLUMN_ID_REGEX);
  // already has correct format -> return it
  if (regEx.test(columnId)) return columnId;
  return `formula(${columnId})`;
}

/**
 * from `formula(column_id)` to `column_id`
 * @param fxColumnId
 * @returns
 */
export function removeFormulaAnnotation(fxColumnId: string): string {
  const regEx = new RegExp(FX_COLUMN_ID_REGEX);
  const matchArr = regEx.exec(fxColumnId);
  invariant(matchArr, 'invalid fx column id: ' + fxColumnId);
  return matchArr[1];
}

export function createFxReferenceMap({
  targetFx,
  columnsConfig,
}: {
  targetFx: string;
  columnsConfig: CustomColumnsConfigMap;
}): Record<string, string> {
  const srcCustomFormula: Record<string, string> = {};

  const regEx = new RegExp(`(${FX_COLUMN_ID_REGEX})`, 'gi');

  const _recursivelyResolveReferencedFxes = (_fx: string) => {
    for (const fxMatchArr of Array.from(_fx.matchAll(regEx))) {
      // const [, fxColId, colId] = fxMatchArr;
      const [, fxColId] = fxMatchArr;
      const colFx = columnsConfig[fxColId]?.formula;
      const colId = removeFormulaAnnotation(fxColId);
      // skip when is already added
      if (srcCustomFormula[colId]) continue;
      if (!colFx) continue;
      srcCustomFormula[colId] = colFx;
      _recursivelyResolveReferencedFxes(colFx);
    }
  };

  _recursivelyResolveReferencedFxes(targetFx);

  return srcCustomFormula;
}

export function flatFxReferenceMap({
  targetFx,
  columnsConfig,
}: {
  targetFx: string;
  columnsConfig: CustomColumnsConfigMap;
}): string {
  const regEx = new RegExp(`(${FX_COLUMN_ID_REGEX})`, 'gi');

  const _recursivelyResolveReferencedFxes = (_fx: string): string => {
    let result: string = _fx;

    for (const fxMatchArr of Array.from(_fx.matchAll(regEx))) {
      // const [, fxColId, colId] = fxMatchArr;
      // const [, fxColId] = fxMatchArr;
      const fxColId = fxMatchArr[1];
      const colFx = columnsConfig[fxColId]?.formula;

      // skip when is already added
      if (!colFx) continue;

      result = result.replaceAll(
        `{${fxColId}}`,
        _recursivelyResolveReferencedFxes(colFx),
      );
    }

    return result;
  };

  const resolvedFx = _recursivelyResolveReferencedFxes(targetFx);

  return wrapSumAnalyticalFx(resolvedFx);
}

export function flattenFx({
  targetFx,
  columnsConfig,
}: {
  targetFx: string;
  columnsConfig: CustomColumnsConfigMap;
}): { resolvedFx: string; refMap: Record<string, string> } {
  const refMap: Record<string, string> = {};
  let resolvedFx = targetFx;

  const referenceColumnRegEx = new RegExp(
    `(\\s*\\{(${COLUMN_ID_REGEX}|(${FX_COLUMN_ID_REGEX}))\\}\\s*)`,
    'gi',
  );

  function $replaceAll(_str: string): string {
    return _str.replaceAll(referenceColumnRegEx, (...matchArr) => {
      const replaceableString = matchArr[0];
      const colId = matchArr[2];
      const fxColId = matchArr[5];

      let finalColId = null;
      let columnFxContent = replaceableString;

      if (fxColId && columnsConfig[colId]?.formula) {
        columnFxContent = columnsConfig[colId].formula!;
        finalColId = removeFormulaAnnotation(colId);
      } else if (fxColId && columnsConfig[fxColId]?.formula) {
        columnFxContent = columnsConfig[fxColId].formula!;
        finalColId = removeFormulaAnnotation(fxColId);
      } else if (colId && columnsConfig[colId]?.formula) {
        columnFxContent = columnsConfig[colId].formula!;
        finalColId = colId;
      }

      // if an analytical fx is found, we need to recursively resolve it
      if (isAnalyticalFx(columnFxContent, columnsConfig)) {
        if (columnFxContent != replaceableString) {
          // wrap the content in parentheses to make sure it's work in case of division
          // for example: {query_cost} / ({storage_cost} + 6)
          return `(${$replaceAll(columnFxContent)})`;
        }
      }

      // else do nothing, just return the original string
      // but include it in refMap to make sure it has enough information to be resolved
      if (finalColId) refMap[finalColId] = columnFxContent;

      return replaceableString;
    });
  }

  resolvedFx = $replaceAll(targetFx);

  return { resolvedFx, refMap };
}
