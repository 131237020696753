import type { RequestManager } from '@/adapters/api/__base';
import type {
  CreateWidgetPayload,
  UpdateWidgetPayload,
  UpdateWidgetSizePayload,
  UpdateWidgetSizeResponse,
} from '@/feature-dashboard/widget/api';
import type { WidgetDto } from '@/models/widget';

import invariant from 'tiny-invariant';

import { BaseApiAdapterClass } from '@/adapters/api/__base';
import { UpdateWidgetSizeResponseSchema } from '@/feature-dashboard/widget/api';
import {
  ViewRecommendation,
  zViewRecommendation,
} from '@/feature-report/ai-prompt/model';
import {
  NO_PERMISSION_ACCESS_DASHBOARD_API_ERROR,
  NO_PERMISSION_ACCESS_TEAM_API_ERROR,
} from '@/initializers/AppErrorBoundary';
import { WidgetDtoSchema } from '@/models/widget';
import { serializeArrayData, serializeData } from '@/utils/data';
import { isUuid } from '@/utils/string';

import { clientHttp, serverHttp } from './axios';
import { createFetchRequestManager } from './fetch-http-client';
import { widgetUrl } from './url-string';

class WidgetApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  async create(
    teamId: string,
    dashboardId: string,
    payload: CreateWidgetPayload,
  ): Promise<WidgetDto | null> {
    const url = `/teams/${teamId}/dashboards/${dashboardId}/widgets`;

    const data = await this.request<WidgetDto, CreateWidgetPayload>(
      url,
      'post',
      payload,
    );

    return serializeData(data, WidgetDtoSchema);
  }

  async remove(teamId: string, dashboardId: string, widgetId: string) {
    await this.request(
      `/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}`,
      'delete',
    );
  }

  async update(
    teamId: string,
    dashboardId: string,
    widgetId: string,
    payload: UpdateWidgetPayload,
  ): Promise<WidgetDto | null> {
    const url = widgetUrl.detail(teamId, dashboardId, widgetId);

    const data = await this.request<WidgetDto, UpdateWidgetPayload>(
      url,
      'put',
      payload,
    );

    return serializeData(data, WidgetDtoSchema);
  }

  async duplicate(
    teamId: string,
    dashboardId: string,
    widgetId: string,
    targetDashboardId?: string,
  ) {
    const url = widgetUrl.duplicate(teamId, dashboardId, widgetId);

    const data = await this.request<
      WidgetDto,
      { target_dashboard_id?: string }
    >(url, 'post', { target_dashboard_id: targetDashboardId });

    return serializeData(data, WidgetDtoSchema);
  }

  async getAll(teamId: string, dashboardId: string): Promise<WidgetDto[]> {
    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    // NOTE: check valid dashboardId
    if (!isUuid(dashboardId)) {
      throw NO_PERMISSION_ACCESS_DASHBOARD_API_ERROR;
    }
    const data = await this.get<WidgetDto[]>(
      `/teams/${teamId}/dashboards/${dashboardId}/widgets`,
    );
    return serializeArrayData(data ?? [], WidgetDtoSchema);
  }

  async updateSizes(
    teamId: string,
    dashboardId: string,
    payload: UpdateWidgetSizePayload[],
  ): Promise<UpdateWidgetSizeResponse[] | null> {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');
    if (payload.length === 0) return [];

    const url =
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/widgets/sizes';

    return await this.request<
      UpdateWidgetSizeResponse[],
      UpdateWidgetSizePayload[]
    >(url, 'put', payload);

    // return serializeArrayData(data ?? [], UpdateWidgetSizeResponseSchema);
  }

  async getDetail(teamId: string, dashboardId: string, widgetId: string) {
    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    // NOTE: check valid dashboardId
    if (!isUuid(dashboardId)) {
      throw NO_PERMISSION_ACCESS_DASHBOARD_API_ERROR;
    }

    const url = `/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}`;
    const data = await this.get<WidgetDto>(url);

    const safeResponse = serializeData(data, WidgetDtoSchema);
    return safeResponse;
  }

  async createViewRecommendation(
    teamId: string | null,
    dashboardId: string | null,
    widgetId: string | null,
    viewId: string,
    userRequest: string,
  ) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');
    invariant(widgetId, 'no widget id provided');

    const url = `/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}/report-views/${viewId}/config-recommendations`;
    const data = await this.request<
      ViewRecommendation,
      { user_request: string }
    >(url, 'post', { user_request: userRequest }, undefined, undefined, {
      timeout: 90 * 1000,
    });
    //serialize so that grouping wont be null
    const safeData = serializeData(data, zViewRecommendation);
    return safeData;
  }

  async getViewRecommendation(
    teamId: string | null,
    dashboardId: string | null,
    widgetId: string | null,
    viewId: string | null,
    recommendationId: string,
  ) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');
    invariant(widgetId, 'no widget id provided');
    invariant(viewId, 'no view id provided');

    const url = `/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}/report-views/${viewId}/config-recommendations/${recommendationId}`;
    const data = await this.get<ViewRecommendation>(url);
    const safeData = serializeData(data, zViewRecommendation);
    // if (data?.status === 'COMPLETED')
    //   viewConfigIsValidForDisplay(data?.suggested_view_config);
    return safeData;
  }

  async getPublishedAll(
    teamId: string,
    dashboardId: string,
  ): Promise<WidgetDto[]> {
    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    // NOTE: check valid dashboardId
    if (!isUuid(dashboardId)) {
      throw NO_PERMISSION_ACCESS_DASHBOARD_API_ERROR;
    }
    const data = await this.get<WidgetDto[]>(
      `/teams/${teamId}/dashboards/${dashboardId}/widgets`,
    );

    return data ?? [];
  }
}

/**
 * @deprecated
 */
export const WidgetApiAdapter = Object.freeze(
  new WidgetApiAdapterClass(clientHttp),
);

export const WidgetApi = {
  onBrowser: () => new WidgetApiAdapterClass(clientHttp),
  onServer: () => new WidgetApiAdapterClass(serverHttp),
  onServerFetch: (fetchOptions?: RequestInit) =>
    new WidgetApiAdapterClass(createFetchRequestManager(fetchOptions)),
};
