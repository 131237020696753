'use client';

import type { CustomColumnsConfigMap } from '@/models/common';
import type { DataModelColumn } from '@/models/data-model';

import { useCallback, useEffect, useMemo } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalTextEntity } from '@lexical/react/useLexicalTextEntity';
import {
  $isParentElementRTL,
  $moveCaretSelection,
  $shouldOverrideDefaultCharacterSelection,
} from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import {
  $getAdjacentNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  RangeSelection,
  TextNode,
} from 'lexical';
import invariant from 'tiny-invariant';

import {
  ALPHA,
  ALPHANUMERIC,
  LEFT_CURLY_BRACKET,
  RIGHT_CURLY_BRACKET,
} from '../../constants';
import { INSERT_MODEL_COLUMN_COMMAND } from '../../fx-lexical-commands';
import {
  $createModelPropertyNode,
  $isModelPropertyNode,
  ModelPropertyNode,
} from '../model-property-node';
import LexicalUtils from './LexicalUtils';

function getCurlyBracketsRegexStringChars(): Readonly<{
  alpha: string;
  alphanumeric: string;
  leftCurlyBracket: string;
  rightCurlyBracket: string;
}> {
  const leftCurlyBracket = LEFT_CURLY_BRACKET + '\\uFF5B'; // normal '{' or full-width '{'
  const rightCurlyBracket = RIGHT_CURLY_BRACKET + '\\uFF5D'; // normal '}' or full-width '}'

  return {
    alpha: ALPHA,
    alphanumeric: ALPHANUMERIC,
    leftCurlyBracket,
    rightCurlyBracket,
  };
}

function getModelPropertyRegexStr(): string {
  // const { alphanumeric, leftCurlyBracket, rightCurlyBracket } =
  //   getCurlyBracketsRegexStringChars();

  // const boundaryCharList = '^|$|[^&' + alphanumeric + ']';
  // // const alphaCharList = `[${alpha}\\s]`; // NOTE: allow properties to have white spaces in the middle!
  // // const alphanumericCharList = '[' + alphanumeric + ']';
  // const leftCurlyCharList = '[' + leftCurlyBracket + ']';
  // const rightCurlyCharList = '[' + rightCurlyBracket + ']';

  // // const regex = `(${boundaryCharList})(${leftCurlyCharList})${COLUMN_ID_REGEX}(${rightCurlyCharList})`;
  // const regex = `(${boundaryCharList})(${leftCurlyCharList})((${FX_COLUMN_ID_REGEX})|${COLUMN_ID_REGEX})(${rightCurlyCharList})`;

  // return regex;

  const { leftCurlyBracket, rightCurlyBracket } =
    getCurlyBracketsRegexStringChars();

  // Simplified regex to match everything between curly braces
  const regex = `[${leftCurlyBracket}]([^${rightCurlyBracket}]*)[${rightCurlyBracket}]`;

  return regex;
}

// const MODEL_PROPERTY_REGEX = new RegExp(getModelPropertyRegexStr(), 'i');

function extractModelProperty(text: string) {
  const MODEL_PROPERTY_REGEX = new RegExp(getModelPropertyRegexStr(), 'i');

  const matchArr = MODEL_PROPERTY_REGEX.exec(text);

  if (matchArr === null) {
    return null;
  }

  // // const [, leftParenthesis, , colId, , , , rightCurlyBraces] = matchArr;
  // const leftParenthesis = matchArr[1];
  // const colId = matchArr[3];
  // const rightCurlyBraces = matchArr[7];

  // // const modelPropertyLength = colId.length + 1;

  // // const startOffset = matchArr.index + leftParenthesis.length;

  // // const endOffset =
  // //   startOffset + modelPropertyLength + rightCurlyBraces.length;

  // return {
  //   matchIndex: matchArr.index,
  //   leftParenthesis,
  //   colId,
  //   rightCurlyBraces,
  // };

  return {
    matchIndex: matchArr.index,
    columnId: matchArr[1],
  };
}

function getModelPropertyMatch(text: string) {
  // NOTE: extract model property
  const matchRes = extractModelProperty(text);

  if (matchRes === null) {
    return null;
  }

  // const { matchIndex, leftParenthesis, colId, rightCurlyBraces } = matchRes;

  // const modelPropertyLength = colId.length + 1;

  // // const startOffset = matchArr.index + leftParenthesis.length;
  // const startOffset = matchIndex + leftParenthesis.length;

  // const endOffset = startOffset + modelPropertyLength + rightCurlyBraces.length;

  // return {
  //   start: startOffset,
  //   end: endOffset,
  // };

  const { matchIndex, columnId } = matchRes;

  const modelPropertyLength = columnId.length;

  const startOffset = matchIndex;

  const endOffset = startOffset + modelPropertyLength + 2;

  return {
    start: startOffset,
    end: endOffset,
  };
}

const DONT_SKIP = 0;

function $getSelectionSkipCharacters(
  selection: RangeSelection,
  isBackward: boolean,
): number {
  const isSkippedByDefault = $shouldOverrideDefaultCharacterSelection(
    selection,
    isBackward,
  );

  if (isSkippedByDefault) return 1;

  const possibleNode = $getAdjacentNode(selection.focus, isBackward);

  if ($isModelPropertyNode(possibleNode))
    return possibleNode.getTextContentSize();

  const selectedNodes = selection.getNodes();

  if (selectedNodes.length === 1 && $isModelPropertyNode(selectedNodes[0])) {
    return selectedNodes[0].getTextContentSize();
  }

  return DONT_SKIP;
}

function $moveCharacter(
  selection: RangeSelection,
  isHoldingShift: boolean,
  isBackward: boolean,
): void {
  const isRTL = $isParentElementRTL(selection);
  $moveCaretSelection(
    selection,
    isHoldingShift,
    isBackward ? !isRTL : isRTL,
    'character',
  );
}

type Props = {
  columnsConfig: CustomColumnsConfigMap;
  modelColumns: DataModelColumn[];
};

export const useModelPropertyNodeRegister = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNode(ModelPropertyNode)) {
      invariant(false, 'no ModelPropertyNode registered.');
    }
  }, [editor]);
  return null;
};

export const ColumnParserPlugin = ({ modelColumns, columnsConfig }: Props) => {
  const [editor] = useLexicalComposerContext();

  const createModelPropertyNode = useCallback(
    (textNode: TextNode): ModelPropertyNode => {
      const content = textNode.getTextContent();

      const matchRes = extractModelProperty(content);

      invariant(matchRes, 'invalid match');

      const { columnId } = matchRes;

      let handleColumnId = columnId;

      const col = modelColumns.find((col) => col.column_name === columnId);

      /**
       * This handle when users type an alias, we'll auto-convert it to a correct model column
       */
      if (!col) {
        const maybeAlias = columnId;
        const exactCol = modelColumns.find(
          (col) => columnsConfig[col.column_name]?.alias === maybeAlias,
        );
        // alias detected!
        if (exactCol) {
          handleColumnId = exactCol.column_name;
        }
      }

      const conlumnConf = columnsConfig[handleColumnId];

      return $createModelPropertyNode(content, {
        columnId: handleColumnId,
        alias: conlumnConf?.alias ?? undefined,
      });
    },
    [columnsConfig, modelColumns],
  );

  useModelPropertyNodeRegister();

  useLexicalTextEntity<ModelPropertyNode>(
    getModelPropertyMatch,
    ModelPropertyNode,
    createModelPropertyNode,
  );

  // NOTE: insert model command handler
  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          INSERT_MODEL_COLUMN_COMMAND,
          (payload) => {
            const { columnId } = payload;

            const conlumnConf = columnsConfig[columnId];

            const columnNode = $createModelPropertyNode(`{${columnId}}`, {
              columnId,
              alias: conlumnConf?.alias ?? undefined,
            });

            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
              selection.insertNodes([columnNode]);
              return true;
            }

            const lastChild = LexicalUtils.$getRootLastElementChild();

            lastChild.append(columnNode);

            return true;
          },
          COMMAND_PRIORITY_EDITOR,
        ),
        // editor.registerCommand<KeyboardEvent>(
        //   KEY_ARROW_LEFT_COMMAND,
        //   (payload) => {
        //     const selection = $getSelection();

        //     if (!$isRangeSelection(selection)) {
        //       return false;
        //     }

        //     const event = payload;
        //     const isHoldingShift = event.shiftKey;

        //     const skipChars = $getSelectionSkipCharacters(selection, true);

        //     if (skipChars) {
        //       event.preventDefault();
        //       for (let index = 0; index < skipChars; index++) {
        //         $moveCharacter(selection, isHoldingShift, true);
        //       }
        //       return true;
        //     }

        //     return false;
        //   },
        //   COMMAND_PRIORITY_EDITOR,
        // ),
        // editor.registerCommand<KeyboardEvent>(
        //   KEY_ARROW_RIGHT_COMMAND,
        //   (payload) => {
        //     const selection = $getSelection();

        //     if (!$isRangeSelection(selection)) {
        //       return false;
        //     }

        //     const event = payload;
        //     const isHoldingShift = event.shiftKey;

        //     const skipChars = $getSelectionSkipCharacters(selection, false);

        //     if (skipChars) {
        //       event.preventDefault();
        //       for (let index = 0; index < skipChars; index++) {
        //         $moveCharacter(selection, isHoldingShift, false);
        //       }
        //       return true;
        //     }

        //     return false;
        //   },
        //   COMMAND_PRIORITY_EDITOR,
        // ),
      ),
    [editor, columnsConfig],
  );

  return null;
};
