import type {
  AggregateType,
  ColumnType,
  TimeSeriesPeriod,
} from '@/models/view';

import { IPresetOption, PresetLabel } from '@/@types';
import { NumberOperator, TextOperator, ViewType } from '@/models/view';

export const DATE_SEPARATOR = '~';

export const ISO_HOUR_FORMAT = 'HH:mm:ssZ';

export const DATE_FORMAT_deprecated = 'MM.DD.YYYY';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ[Z]';

export const FILTER_DATE_FORMAT = 'YYYY. MM. DD.';

export const DETAIL_DATE_FORMAT = 'MMM DD, YYYY [at] h:mm:ss A';
export const SHORT_HOUR_FORMAT = 'h:mm A';
export const SHORT_DATE_FORMAT = 'MMM. D - h:mm A';

export const COMPARE_WITH_PAST = 'COMPARE_WITH_PAST';
export const AGGREGATED_COLUMN = 'AGGREGATED_COLUMN';
export const PAST_COL_DATA = 'PAST_COL_DATA';

export const MILLISECOND_PER_DAY = 24 * 60 * 60 * 1000;
export const MILLISECOND_PER_WEEK = MILLISECOND_PER_DAY * 7;
export const DATE_SELECT_OPTS: ReadonlyArray<{
  label: PresetLabel;
  iconName: string;
  values: ReadonlyArray<IPresetOption>;
}> = [
  {
    label: 'this-and-last',
    iconName: 'ic_setting_box_24_inbox',
    values: [
      { id: 'TODAY', value: 'today', type: 'day' },
      { id: 'YESTERDAY', value: 'yesterday', type: 'day' },

      { id: 'THIS_WEEK', value: 'this', type: 'week' },
      { id: 'LAST_WEEK', value: 'last', type: 'week' },

      { id: 'THIS_MONTH', value: 'this', type: 'month' },
      { id: 'LAST_MONTH', value: 'last', type: 'month' },

      { id: 'THIS_QUARTER', value: 'this', type: 'quarter' },
      { id: 'LAST_QUARTER', value: 'last', type: 'quarter' },

      { id: 'THIS_YEAR', value: 'this', type: 'year' },
      { id: 'LAST_YEAR', value: 'last', type: 'year' },
    ],
  },
  {
    label: 'time-to',
    iconName: 'briefcase-2-line',
    values: [
      { id: 'WEEK_TO_DATE', value: 'today', type: 'week' },
      { id: 'MONTH_TO_DATE', value: 'today', type: 'month' },
      { id: 'QUARTER_TO_DATE', value: 'today', type: 'quarter' },
      { id: 'YEAR_TO_DATE', value: 'today', type: 'year' },
      { id: 'WEEK_TO_YESTERDAY', value: 'yesterday', type: 'week' },
      { id: 'MONTH_TO_YESTERDAY', value: 'yesterday', type: 'month' },
      { id: 'QUARTER_TO_YESTERDAY', value: 'yesterday', type: 'quarter' },
      { id: 'YEAR_TO_YESTERDAY', value: 'yesterday', type: 'year' },
    ],
  },
  {
    label: 'last-period',
    iconName: 'calendar-schedule-line',
    values: [
      { id: 'RECENT_7_DAYS', value: 7, type: 'day' },
      { id: 'RECENT_14_DAYS', value: 14, type: 'day' },
      { id: 'RECENT_30_DAYS', value: 30, type: 'day' },
      {
        id: 'RECENT_3_MONTHS',
        value: 3,
        type: 'month',
      },
      {
        id: 'RECENT_6_MONTHS',
        value: 6,
        type: 'month',
      },
      {
        id: 'RECENT_12_MONTHS',
        value: 12,
        type: 'month',
      },
    ],
  },
  {
    label: 'general',
    iconName: 'calendar-line',
    values: [{ id: 'CUSTOM', value: 'custom', type: 'day' }],
  },
];

export const COLUMN_TYPE_ICON: Record<ColumnType | 'JSON', string> = {
  DATETIME: 'ic_calendar_16',
  TEXT: 'typography',
  JSON: 'typography',
  NUMBER: 'numbers',
  BOOLEAN: 'ic_boolean',
  ARRAY: 'ic_array',
  ARRAY_TEXT: 'ic_array',
  ARRAY_NUMBER: 'ic_array',
  ARRAY_BOOLEAN: 'ic_array',
  ARRAY_DATETIME: 'ic_array',
};

export const APP_ICON_OBJ = {
  ...COLUMN_TYPE_ICON,
  ALL: 'ic_advance_filter1',
  SORT: 'ic_filters_16',
  ADVANCED: 'ic_advance_filter1',
};

export const APP_FILTER_TYPE = {
  DATETIME: 'Date' as 'Date',
  TEXT: 'String' as 'String',
  NUMBER: 'Number' as 'Number',
  BOOLEAN: 'Boolean' as 'Boolean',
};

export const FILTER_OPERATOR_OPTIONS = ['AND', 'OR'] as const;

export const DEFAULT_FILTER_VALUE: Record<ColumnType | 'JSON', any> = {
  DATETIME: {
    from_time: null,
    to_time: null,
    time_range: 'RECENT_7_DAYS' as const,
    first_day_of_week: 'MONDAY' as const,
  },
  TEXT: {
    value: null,
    operator: 'IS' as TextOperator,
  },
  JSON: {
    value: null,
    operator: 'IS' as TextOperator,
  },
  NUMBER: {
    value: null,
    operator: 'GREATER_THAN' as NumberOperator,
  },
  BOOLEAN: {
    value: true,
  },
  ARRAY: null,
  ARRAY_TEXT: null,
  ARRAY_NUMBER: null,
  ARRAY_BOOLEAN: null,
  ARRAY_DATETIME: null,
};

export const TEXT_OPERATOR_OPTIONS: TextOperator[] = [
  'IS',
  'IS_NOT',
  'CONTAIN',
  'NOT_CONTAIN',
];

export const NUMBER_OPERATOR_MAP = {
  GREATER_THAN: '>',
  GREATER_OR_EQUAL: '>=',
  LESS_THAN: '<',
  LESS_OR_EQUAL: '<=',
  EQUAL: '=',
} as const;

export const NUMBER_OPERATOR_OPTIONS = (
  [
    'GREATER_THAN',
    'GREATER_OR_EQUAL',
    'LESS_THAN',
    'LESS_OR_EQUAL',
    'EQUAL',
  ] as NumberOperator[]
).map((value) => ({
  label: NUMBER_OPERATOR_MAP[value as NumberOperator],
  value: value,
}));

export const FLOAT_MENU_OPT_ICON_NAME = {
  'ai-assistant': 'ic_magic_16',
  'remove-ai-assistant': 'ic_magic_16',
  'add-favorites': 'ic_add_fav',
  'delete-label': 'ic_Delete_12',
  'duplicate-label': 'ic_duplicate',
  'new-report-label': 'ic_add_report',
  'remove-favorites': 'ic_remove_fav',
  'rename-label': 'ic_rename',
  'share-label': 'ic_share',
  'rich-text-label': 'ic_add_note',
  'header-note-label': 'heading',
  'add-description': 'ic_description',
  'remove-description': 'ic_remove_description',
  'intro-label': 'ic_intro_18',
  'docs-label': 'book-2-line',
  'alert-label': 'notification-2-line',
  'account-label': 'ic_account_18',
  'invite-label': 'ic_invite_18',
  'billing-label': 'ic_billing_18',
  'usage-amount-label': 'ic_usage_18',
  'sign-out-label': 'ic_sign_out_18',
  'add-widget': 'ic_create_new_24',
  'move-label': 'share-forward-box-line',
  'sync-label': 'ic_sync_18',
  'upload-label': 'ic_file_18',
  'export-label': 'ic_export_16',
  'add-field': 'add-fill',
  'clear-all': 'close-line',
  'view-detail': 'eye-line',
  'download-label': 'download-2-line',
  favorite: 'ic_add_fav',
  refresh: 'loop-left-line',
  'comparison-method': 'ic_comparison_method',
  'edit-style': 'pencil-line',
} as const;

export const NOOP_FN = () => {};

export const FNS_FOR_TEXT_TYPE: AggregateType[] = [
  'COUNT',
  'COUNT_DISTINCT',
  'COUNT_EMPTY',
  'COUNT_NOT_EMPTY',
] as const;

export const FNS_FOR_NUMBER_TYPE: AggregateType[] = [
  'SUM',
  'AVG',
  'MIN',
  'MAX',
  // for text
  ...FNS_FOR_TEXT_TYPE,
  // for fx
  // 'FORMULA',
  // 'AGG',
  // 'SINGLE_ROW_AGGREGATE',
] as const;

export const CLASSNAME_ICON_CIGRO = 'cg-app-icon';

export const CLASSNAME_DONT_PRINT = 'cg-dont-print-this';

export const DASHBOARD_PRINTING_CLASSNAMES = {
  CgDashboardTitle: 'CgDashboardTitle',
  CgDashboardMenuGroup: 'CgDashboardMenuGroup',
  CgDashboardFilterSection: 'CgDashboardFilterSection',
  CgDashboardFilters: 'CgDashboardFilters',
  CgMoreActionsMenu: 'CgMoreActionsMenu',
  CgFilterActions: 'CgFilterActions',
  CgWidgetRow: 'CgWidgetRow',
  CgWidgetCreateButton: 'CgWidgetCreateButton',
  CgWidgetRefreshButton: 'CgWidgetRefreshButton',
  CgWidgetViewTabs: 'CgWidgetViewTabs',
};

export const APP_LOGO_PATH = '/assets/logo.png';

export const APP_NAME = 'Endash';
export const ICON_BY_SORT_STATE: Record<string, string> = {
  false: 'ic_sort_20',
  asc: 'ic_sort_20_rounded_asc',
  desc: 'ic_sort_20_rounded_desc',
};

export const DAYS_OF_WEEK = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const;

export const VIEW_TYPE_ICON_MAP: Record<ViewType, string> = {
  TABLE: 'ic_chart_28_table1',
  BAR_CHART: 'ic_chart_28_bar1',
  LINE_CHART: 'ic_chart_28_line1',
  PIE_CHART: 'ic_chart_28_pie1',
  BOARD: 'ic_chart_28_board',
  PIVOT_TABLE: 'ic_chart_28_pivot',
  GALLERY: 'image-line',
  GOAL: 'focus-2-line',
  BAR_AND_LINE_COMBINED_CHART: 'ic_data_categories_analysis_18',
  RANK_CHART: 'ic_rank_chart',
};

export const PERIODICITIES: TimeSeriesPeriod[] = [
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
];
