import { memoize } from 'lodash';
import { grammar } from 'ohm-js';
import { toAST } from 'ohm-js/extras';

import {
  AGG_FN_TYPE,
  ANALYTICAL_FN_TYPE,
  CONDITION_TYPE,
  IF_EXPRESSION_TYPE,
  INDEX_EXPRESSION_TYPE,
  SPLIT_EXPRESSION_TYPE,
} from './constant';
import { OHM_RULES_FN } from './index';

export const checkFnGrammar = (text: string) => {
  const FnGrammar = grammar(OHM_RULES_FN);

  return FnGrammar.match(text);
};

export type FunctionType =
  | typeof AGG_FN_TYPE
  | typeof ANALYTICAL_FN_TYPE
  | typeof IF_EXPRESSION_TYPE
  | typeof INDEX_EXPRESSION_TYPE
  | typeof SPLIT_EXPRESSION_TYPE;

export const recursiveCheckFn = (
  matchResult: {
    type?: string;
    [key: number]: any;
  },
  fnType: FunctionType,
): boolean => {
  if (!matchResult) return false;

  if (matchResult.type === 'linebreak') return false;

  // NOTE: condition in If ELSE is not defined type of formula, ignore it
  if (matchResult.type === CONDITION_TYPE) return false;

  // Check the current node
  if (matchResult.type === fnType) {
    return true;
  }

  let result = false;

  // Recursively check children (if they exist as object)
  Object.values(matchResult).forEach((child) => {
    if (typeof child === 'object' && recursiveCheckFn(child, fnType)) {
      result = true;
    }
  });

  return result;
};

export const checkContainFns = memoize(
  (formula: string, fnType: FunctionType) => {
    const matchResult = checkFnGrammar(formula);

    if (matchResult.failed()) return false;

    const ast = toAST(matchResult);

    return recursiveCheckFn(ast, fnType);
  },
  (formula, fnType) => [formula, fnType].join('_'),
);
