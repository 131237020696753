'use client';

import type { Session } from 'next-auth';
import type { PropsWithChildren } from 'react';

import React, { useCallback } from 'react';

import { SessionProvider, useSession } from 'next-auth/react';

import { tokenManager } from '@/adapters/api/axios/token-manager';

import { AxiosBearerSynchronizer } from './AxiosBearerSynchronizer';

const JwtRefresher = () => {
  const { data: session, status: sessionStatus } = useSession();

  const onJwtExpired = useCallback(async () => {
    if (!session?.access_token) return;
    const newSession = await tokenManager.refreshToken();
    console.debug(
      'manually fetched new session [%s] => [%s]',
      session.access_token.slice(-6),
      newSession?.jwt?.slice(-6),
    );
  }, [session?.access_token]);

  if (sessionStatus === 'loading') return null;

  if (sessionStatus === 'unauthenticated') return null;

  if (session?.error != null) return null;

  if (!session?.access_token || !session?.access_token_expires_at) {
    return null;
  }

  return (
    <AxiosBearerSynchronizer
      jwt={session.access_token}
      expiresAt={session.access_token_expires_at}
      onJwtExpired={onJwtExpired}
      // onJwtExpiredAfter={onJwtExpiredAfter}
    />
  );
};

const NextAuthSessionProvider: React.FC<
  PropsWithChildren<{
    session?: Session;
  }>
> = ({ session, children }) => {
  return (
    <SessionProvider session={session} refetchOnWindowFocus={false}>
      <JwtRefresher />
      {children}
    </SessionProvider>
  );
};

export default NextAuthSessionProvider;
