import dayjs from 'dayjs';
import { getSession } from 'next-auth/react';

const BUFFER_SECONDS = 10; // Buffer time before actual expiration

function expiresInSeconds(expiresAtUtcDateString: string): number {
  const nowUtc = dayjs(new Date().toUTCString());
  const expirationUtc = dayjs(expiresAtUtcDateString);
  return expirationUtc.diff(nowUtc, 'seconds');
}

class TokenManager {
  private static instance: TokenManager;
  private listeners: Set<(token: Token | null) => void> = new Set();
  private currentToken: Token | null = null;
  private tokenPromise: Promise<Token | null> | null = null;
  private resolveTokenPromise: ((token: Token | null) => void) | null = null;
  private refreshPromise: Promise<Token | null> | null = null;

  private constructor() {}

  static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager();
    }
    return TokenManager.instance;
  }

  private isTokenValid(token: Token): boolean {
    if (!token) return false;
    const expiresIn = expiresInSeconds(token.expiresAt);
    return expiresIn > BUFFER_SECONDS; // Buffer of 10 seconds
  }

  subscribe(listener: TokenListener): () => void {
    this.listeners.add(listener);
    listener(this.currentToken);
    return () => this.listeners.delete(listener);
  }

  private notifyListeners() {
    this.listeners.forEach((listener) => listener(this.currentToken));
  }

  setToken(token: Token | null) {
    this.currentToken = token;
    this.notifyListeners();

    if (this.resolveTokenPromise) {
      this.resolveTokenPromise(token);
      this.resolveTokenPromise = null;
      this.tokenPromise = null;
    }
  }

  getToken(): Token | null {
    return this.currentToken;
  }

  async waitForToken(): Promise<Token | null> {
    // If we have a valid token, return it
    if (this.currentToken && this.isTokenValid(this.currentToken)) {
      return this.currentToken;
    }

    // If token is invalid, trigger refresh
    if (this.currentToken && !this.isTokenValid(this.currentToken)) {
      return this.refreshToken();
    }

    // If no token, wait for one
    if (!this.tokenPromise) {
      this.tokenPromise = new Promise((resolve) => {
        this.resolveTokenPromise = resolve;
      });
    }

    return this.tokenPromise;
  }

  async refreshToken(): Promise<Token | null> {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    try {
      this.refreshPromise = this.performRefresh();
      const newToken = await this.refreshPromise;
      return newToken;
    } finally {
      this.refreshPromise = null;
    }
  }

  private async performRefresh(): Promise<Token | null> {
    try {
      // Cross tabs, windows, etc.
      const newSession = await getSession({ broadcast: true });

      if (!newSession) throw new Error('Refresh failed');

      const token = {
        jwt: newSession.access_token,
        expiresAt: newSession.access_token_expires_at,
      };

      this.setToken(token);
      return token;
    } catch (error) {
      this.setToken(null);
      throw error;
    }
  }
}

type TokenListener = (token: Token | null) => void;

export type Token = {
  jwt: string;
  expiresAt: string;
};

export const tokenManager = TokenManager.getInstance();
