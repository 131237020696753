import type { AxiosInstance } from 'axios';

import { getSession } from 'next-auth/react';
import pMemoize from 'p-memoize';

import { Token, tokenManager } from '@/adapters/api/axios/token-manager';
import { ApiError } from '@/api-error';
import { zApiServerErrorSchema } from '@/models/common';

const HTTP_UNAUTHORIZED_CODE = 401;

/**
 * Response interceptor, try to parse errors into application errors!
 * @param instance
 */
export function registerApiErrorParserResponseInterceptor(
  instance: AxiosInstance,
) {
  const id = instance.interceptors.response.use(
    function (response) {
      // response.data = JSONBigInt.parse(response.data);
      return response;
    },
    async function (error) {
      const errData =
        error?.config?.responseType === 'blob'
          ? JSON.parse(await error.response.data.text())
          : error?.response?.data;

      const parseResult = await zApiServerErrorSchema.safeParseAsync(errData);

      // NOTE: throw like default behavior
      if (!parseResult.success) {
        return Promise.reject(error);
      }

      const { data } = parseResult;

      // NOTE: init ApiError then throw away!

      const apiErr = new ApiError(error, data, error.response?.status);

      return Promise.reject(apiErr);
    },
  );

  return () => {
    instance.interceptors.response.eject(id);
  };
}

/**
 * Memoized version of the `getSession`
 */
export const getSessionMemoized = pMemoize(
  async (expiredJwt: string) => {
    console.log(
      '[%s] has expired, fetch & broadcast new session...',
      !!expiredJwt ? expiredJwt.slice(0, 6) : '<empty-string>',
    );
    // cross-tabs and cross-window refreshed
    return await getSession();
  },
  {
    // NOTE: when key is empty, it will NOT be cached
    cacheKey: (args) => (!!args[0] ? args[0] : Date.now().toString()),
  },
);

export function registerTokenInterceptor(axiosInstance: AxiosInstance) {
  tokenManager.subscribe((token) => {
    if (token?.jwt) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token.jwt}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const token = await tokenManager.waitForToken();
    if (token?.jwt) {
      config.headers.Authorization = `Bearer ${token.jwt}`;
    }
    return config;
  });
}

export function registerTokenRetryInterceptor(axiosInstance: AxiosInstance) {
  let isRefreshing = false;
  let failedQueue: Array<{
    resolve: (token: Token | null) => void;
    reject: (error: any) => void;
  }> = [];

  const processQueue = (token: Token | null, error: any = null) => {
    failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });
    failedQueue = [];
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const token = await tokenManager.refreshToken();
          processQueue(token);
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          processQueue(null, refreshError);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    },
  );
}

export function registerOptionalTokenInterceptor(axiosInstance: AxiosInstance) {
  tokenManager.subscribe((token) => {
    if (token?.jwt) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token.jwt}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const token = tokenManager.getToken();
    if (token?.jwt) {
      config.headers.Authorization = `Bearer ${token.jwt}`;
    }
    return config;
  });
}
