'use client';

import { useEffect } from 'react';

import { useCountDown } from 'ahooks';
import dayjs from 'dayjs';

import { tokenManager } from '@/adapters/api/axios/token-manager';

const SOON_SECS = 10;

function expiresInSeconds(expiresAtUtcDateString: string): number {
  const nowUtc = dayjs(new Date().toUTCString());
  const expirationUtc = dayjs(expiresAtUtcDateString);
  return expirationUtc.diff(nowUtc, 'seconds') - SOON_SECS;
}

function useTokenExpiryCountdown({
  jwt,
  expiresAt,
  onJwtExpired,
  onJwtExpiredAfter,
}: {
  jwt: string;
  expiresAt: string;
  onJwtExpiredAfter?: (secondsLeft: number) => void;
  onJwtExpired: (jwt: string) => void;
}) {
  const [countdown] = useCountDown({
    leftTime: expiresInSeconds(expiresAt) * 1000,
  });

  useEffect(() => {
    const secondsLeft = expiresInSeconds(expiresAt);
    onJwtExpiredAfter?.(secondsLeft);
  }, [onJwtExpiredAfter, expiresAt]);

  useEffect(() => {
    const setToken = {
      jwt: jwt,
      expiresAt: expiresAt,
    };
    tokenManager.setToken(setToken);
    console.debug('Token [%s] dispatched', setToken?.jwt?.slice(-6));
  }, [jwt, expiresAt]);

  useEffect(() => {
    if (countdown / 1000 <= 0) {
      onJwtExpired(jwt);
    }
  }, [onJwtExpired, countdown, jwt]);

  // const { minutes, seconds } = formattedRes;
  // console.debug(`${minutes} minutes ${seconds} seconds`);

  return null;
}

const AxiosBearerSynchronizer = ({
  jwt,
  expiresAt,
  onJwtExpired,
  onJwtExpiredAfter,
}: {
  jwt: string;
  expiresAt: string;
  onJwtExpired: (jwt: string) => void;
  onJwtExpiredAfter?: (secondsLeft: number) => void;
}) => {
  useTokenExpiryCountdown({ jwt, expiresAt, onJwtExpired, onJwtExpiredAfter });
  return null;
};

export { AxiosBearerSynchronizer };
