import { z } from 'zod';

import { zActionCommunicationConfig, zSchedule } from './shared';

export const zSubscription = z.object({
  id: z.string(),
  dashboard_id: z.string(),
  schedule: zSchedule,
  filtered_in_widget_report_view_ids: z.array(z.string()).nullish(),
  subscribe_all_views: z.boolean().nullish(),
  message: z.string().nullish(),
  communication_config: zActionCommunicationConfig,
  creator_member_id: z.string(),
});

export const zSubscriptionPayload = zSubscription.omit({
  id: true,
  creator_member_id: true,
});

export type SubscriptionPayload = z.infer<typeof zSubscriptionPayload>;

export type Subscription = z.infer<typeof zSubscription>;
