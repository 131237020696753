'use client';

import type { CustomColumnsConfigMap } from '@/models/common';
import type { SxProps, Theme } from '@mui/material/styles';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { useEffect, useMemo, useState } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { Typography } from '@mui/material';
import {
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical';
import { useTranslation } from 'next-i18next';

import { DataModelColumn } from '@/models/data-model';

import { isAggFx, isAnalyticalFx } from '../../../utils';
import { IF_FNS } from '../../constants';
import { INSERT_FX_COMMAND } from '../../fx-lexical-commands';
import { ColumnParserPlugin } from './ColumnParserPlugin';
import { FormulaPlainTextPlugin } from './FormulaPlainTextPlugin';
import LexicalUtils from './LexicalUtils';

// create fx insertion plugin: FC
const FxInsertionPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      INSERT_FX_COMMAND,
      (payload) => {
        editor.focus(() => {
          editor.update(() => {
            const selection = $getSelection();

            const fxNode = getFnNode(payload.fn);

            if ($isRangeSelection(selection)) {
              selection.insertNodes([fxNode]);
            } else {
              // maybe no selection
              const lastChild = LexicalUtils.$getRootLastElementChild();
              lastChild.append(fxNode);
            }

            // move caret between parenthesis
            fxNode.selectEnd();
            return;
          });
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return null;
};

const getFnNode = (fn: string) => {
  switch (fn) {
    case IF_FNS:
      return $createTextNode(`if () then
elseif () then
else`);
    default:
      return $createTextNode(`${fn}(`);
  }
};

const FxAggregationPlugin: FC<{
  columnsConfig: CustomColumnsConfigMap;
}> = ({ columnsConfig }) => {
  const { t } = useTranslation('common', { keyPrefix: 'page-report' });

  const isEditable = useLexicalEditable();

  const [editor] = useLexicalComposerContext();
  const [value, setValue] = useState('');

  const isAggregatedColumn = useMemo(
    () => isAggFx(value, columnsConfig),
    [value, columnsConfig],
  );

  const isAnalyticalColumn = useMemo(
    () => isAnalyticalFx(value, columnsConfig),
    [value, columnsConfig],
  );

  // init
  useEffect(() => {
    const content = editor
      .getEditorState()
      .read(() => $getRoot().getTextContent());
    setValue(content);
  }, [editor]);

  // on change
  useEffect(() => {
    return editor.registerTextContentListener((content) => {
      setValue(content);
    });
  }, [editor]);

  return (
    <>
      {isEditable && isAggregatedColumn ? (
        <Typography
          variant="caption2"
          color="neutralV2.25"
          sx={{
            position: 'absolute',
            bottom: '16px',
            left: '8px',
            userSelect: 'none',
          }}
          //
        >
          {t('this-column-will-be-aggregated-column')}
        </Typography>
      ) : isEditable && isAnalyticalColumn ? (
        <Typography
          variant="caption2"
          color="neutralV2.25"
          sx={{
            position: 'absolute',
            bottom: '16px',
            left: '8px',
            userSelect: 'none',
          }}
          //
        >
          {t('this-column-will-be-analytic-column')}
        </Typography>
      ) : null}
    </>
  );
};

const EMPTY_OBJ = {};

type Props = {
  columnsConfig: CustomColumnsConfigMap;
  modelColumns: DataModelColumn[];
  //
  placeholder?: ReactNode;
  hasError?: boolean;
  onChange?: (value: string) => void;
  sx?: SxProps<Theme> | undefined;
};

export const FxEditorPlugins: React.FC<PropsWithChildren<Props>> = ({
  onChange,
  hasError,
  children,
  sx,
  placeholder,
  columnsConfig,
  modelColumns,
}) => (
  <>
    {/* BUILT-IN PLUGINS */}
    <FormulaPlainTextPlugin
      hasError={hasError}
      sx={sx}
      placeholder={placeholder}
      onChange={onChange}
      uiPlugins={<FxAggregationPlugin columnsConfig={columnsConfig} />}
    />

    {/* EXTENDED PLUGINS */}
    <HistoryPlugin />
    <ColumnParserPlugin
      modelColumns={modelColumns}
      columnsConfig={columnsConfig}
    />
    <FxInsertionPlugin />

    {children}
  </>
);
