import { z } from 'zod';

import { zTimeSeriesOption } from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zRankChartConfig = z.object({
  view_type: z.literal('RANK_CHART'),
  time_series: zTimeSeriesOption.nullish(),
  top_n: z.number().min(1).max(10).nullish(),
  label_size: z.number().default(6).nullish(),
  rotate_label: z.boolean().default(false).nullish(),
  show_label: z.boolean().nullish(),
});

export const zRankChartFullConfig =
  zCompatibleCommonConfig.merge(zRankChartConfig);

export type RankChartFullConfig = z.infer<typeof zRankChartFullConfig>;

export const zRankChartRenderingConfig = zRankChartFullConfig;

export type RankChartConfig = z.infer<typeof zRankChartRenderingConfig>;
