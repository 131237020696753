import { z } from 'zod';

import { DAYS_OF_WEEK } from '../../constants';

const zHourlySchedule = z.object({
  type: z.literal('HOURLY'),
  interval_hours: z.number(),
});

export type HourlySchedule = z.infer<typeof zHourlySchedule>;
const zDailySchedule = z.object({
  type: z.literal('DAILY'),
});

export type DailySchedule = z.infer<typeof zDailySchedule>;
const zWeeklySchedule = z.object({
  type: z.literal('WEEKLY'),
  days_of_weeks: z.array(z.enum(DAYS_OF_WEEK)),
});

export type WeeklySchedule = z.infer<typeof zWeeklySchedule>;
const zMonthlySchedule = z.object({
  type: z.literal('MONTHLY'),
  order_numbers: z.array(z.number()),
  days_of_weeks: z.array(z.enum(DAYS_OF_WEEK)),
});

export type MonthlySchedule = z.infer<typeof zMonthlySchedule>;

export const zSchedule = z.intersection(
  z.object({
    start_time: z.string(),
    timezone: z.string(),
  }),
  z.union([zHourlySchedule, zDailySchedule, zWeeklySchedule, zMonthlySchedule]),
);

export type ActionSchedule = z.infer<typeof zSchedule>;

export const zEmailOptions = z.object({
  type: z.literal('EMAIL'),
  email_list: z.array(z.string()),
});

export type EmailOptions = z.infer<typeof zEmailOptions>;

export const zSlackOptions = z.object({
  type: z.literal('SLACK'),
  slack_webhook_urls: z.array(z.string()),
});

export type SlackOptions = z.infer<typeof zSlackOptions>;

export const zActionCommunicationConfig = z.union([
  zEmailOptions,
  zSlackOptions,
]);

export type ActionCommunicationConfig = z.infer<
  typeof zActionCommunicationConfig
>;
