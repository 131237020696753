import type {
  FilterOption,
  TimeRange,
  TimeSeriesPeriod,
  ViewConfig,
} from '@/models/view';

import dayjs, { Dayjs, OpUnitType, QUnitType } from 'dayjs';
import enLocale from 'dayjs/locale/en';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekDay from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import invariant from 'tiny-invariant';

import { DAYS_OF_WEEK, ISO_DATE_FORMAT } from '@/constants';
import { DayOfWeek } from '@/models/common';

import { exhaustiveTypeCheck } from '../type';
import { PERIOD_TO_DAYJS_UNIT_MAPPER, RANGED_DATES_FN_MAPPER } from '.';

dayjs.extend(utc);
dayjs.extend(weekDay);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
// ================== DATE CONSTANTS ===================

const MONTH_FORMAT = 'YYYY-MM';

// ================== GENERAL ===================

export const stringToUtcFormat = (date: string) => {
  return (
    dayjs(date)
      .subtract((new Date().getTimezoneOffset() / 60) * -1, 'hour')
      .format('YYYY-MM-DDTHH:mm:ss') + '+00:00'
  );
};

export const toUtcFormattedDate = (date: Date | string): string => {
  return dayjs(date).utc(true).format(ISO_DATE_FORMAT);
};

// ================== VIEW CONFIG RELATED ===================

export function generateRangedDates(
  fromDate: string,
  toDate: string,
): string[] {
  let startDate = dayjs(fromDate);
  const endDate = dayjs(toDate);

  const dates: string[] = []; // ['2023-05-01', '2023-05-02', ...]
  while (startDate <= endDate) {
    dates.push(startDate.format(ISO_DATE_FORMAT));
    startDate = startDate.set('date', startDate.date() + 1);
  }

  return dates;
}

export function generateWeeklyRangedDates(
  fromDate: string,
  toDate: string,
  weekStart: number,
): string[] {
  const endDate = dayjs(toDate).locale({ ...enLocale, weekStart });

  const dates = []; // ['2023-05-07', '2023-05-14', ...]

  let current = dayjs(fromDate)
    .locale({ ...enLocale, weekStart })
    .startOf('week');

  while (current <= endDate) {
    dates.push(current.format(ISO_DATE_FORMAT));
    current = current.add(1, 'week').startOf('week');
  }
  return dates;
}

export function generateMonthlyRangedDates(
  fromDate: string,
  toDate: string,
): string[] {
  let startDate = dayjs(fromDate);

  const endDate = dayjs(toDate).endOf('month');

  const dates = [];
  while (startDate <= endDate) {
    dates.push(startDate.format(MONTH_FORMAT));
    startDate = startDate.set('month', startDate.month() + 1);
  }
  return dates;
}

export function generateQuarterlyRangedDates(
  fromDate: string,
  toDate: string,
): string[] {
  let startDate = dayjs(fromDate);

  const endDate = dayjs(toDate).endOf('quarter');

  const dates = [];
  while (startDate <= endDate) {
    const quarterString = getQuarterStringFromDate(startDate.toString());
    dates.push(quarterString);
    startDate = startDate.set('month', startDate.month() + 3);
  }
  return dates;
}

export const getQuarterStringFromDate = (date: string) => {
  const dayjsDate = dayjs(date);

  const quarter = dayjsDate.quarter();
  const year = dayjsDate.year();
  return `${year}-Q${quarter}`;
};

export function generateYearlyRangedDates(
  fromDate: string,
  toDate: string,
): string[] {
  let startDate = dayjs(fromDate);

  const endDate = dayjs(toDate).endOf('year');

  const dates = [];
  while (startDate <= endDate) {
    dates.push(startDate.year().toString());
    startDate = startDate.set('year', startDate.year() + 1);
  }
  return dates;
}

export const convertDateToLabelByPeriod = (
  date: string,
  periodicity: TimeSeriesPeriod,
  weekStart: number = 0,
) => {
  let result = dayjs.utc(date).format(ISO_DATE_FORMAT);

  if (periodicity === 'WEEKLY') result = getWeekStartDate(result, weekStart);
  if (periodicity === 'MONTHLY') result = getMonth(result);
  if (periodicity === 'QUARTERLY') result = getQuarterStringFromDate(result);
  if (periodicity === 'YEARLY') result = getYear(result);
  return result;
};

export function fromTimeRangeToClientDateStrings(
  timeRange: TimeRange,
  firstDayOfWeek: number | DayOfWeek,
): [string, string | null] {
  invariant(timeRange !== 'CUSTOM', 'timeRange must not be CUSTOM');
  const fallbackStartOfWeek = firstDayOfWeek ?? 'MONDAY';
  const weekStart =
    typeof fallbackStartOfWeek === 'number'
      ? fallbackStartOfWeek
      : getWeekStart(fallbackStartOfWeek);

  const today = dayjs()
    .locale({ ...enLocale, weekStart })
    .startOf('day');
  const yesterday = today.subtract(1, 'day');

  switch (timeRange) {
    case 'TODAY': {
      return [today.format(ISO_DATE_FORMAT), null];
    }
    case 'YESTERDAY': {
      return [yesterday.format(ISO_DATE_FORMAT), null];
    }
    case 'THIS_WEEK':
    case 'THIS_MONTH':
    case 'THIS_QUARTER':
    case 'THIS_YEAR': {
      const unit = timeRange.split('_')[1];
      const startTime = today.startOf(unit as OpUnitType);
      const endTime = today.endOf(unit as OpUnitType);
      return [
        startTime.format(ISO_DATE_FORMAT),
        endTime.format(ISO_DATE_FORMAT),
      ];
    }
    case 'LAST_WEEK':
    case 'LAST_MONTH':
    case 'LAST_QUARTER':
    case 'LAST_YEAR': {
      const unit = timeRange.split('_')[1];
      const someDayInPast = today.subtract(1, unit as QUnitType);
      const startTime = someDayInPast.startOf(unit as OpUnitType);
      const endTime = someDayInPast.endOf(unit as OpUnitType);
      return [
        startTime.format(ISO_DATE_FORMAT),
        endTime.format(ISO_DATE_FORMAT),
      ];
    }
    case 'RECENT_7_DAYS':
    case 'RECENT_14_DAYS':
    case 'RECENT_30_DAYS': {
      const days = timeRange.split('_')[1];
      const recentNDays = today.subtract(Number(days), 'day');
      return [
        recentNDays.format(ISO_DATE_FORMAT),
        yesterday.format(ISO_DATE_FORMAT),
      ];
    }
    case 'RECENT_3_MONTHS':
    case 'RECENT_6_MONTHS':
    case 'RECENT_12_MONTHS': {
      const months = timeRange.split('_')[1];
      const recentNMonths = today.subtract(Number(months), 'month');
      return [
        recentNMonths.format(ISO_DATE_FORMAT),
        yesterday.format(ISO_DATE_FORMAT),
      ];
    }
    case 'WEEK_TO_DATE':
    case 'MONTH_TO_DATE':
    case 'QUARTER_TO_DATE':
    case 'YEAR_TO_DATE': {
      const unit = timeRange.split('_')[0];

      const startTime = today.startOf(unit as OpUnitType);
      return [startTime.format(ISO_DATE_FORMAT), today.format(ISO_DATE_FORMAT)];
    }
    case 'WEEK_TO_YESTERDAY':
    case 'MONTH_TO_YESTERDAY':
    case 'QUARTER_TO_YESTERDAY':
    case 'YEAR_TO_YESTERDAY': {
      const unit = timeRange.split('_')[0];

      const startTime = yesterday.startOf(unit as OpUnitType);
      return [
        startTime.format(ISO_DATE_FORMAT),
        yesterday.format(ISO_DATE_FORMAT),
      ];
    }
    default:
      return exhaustiveTypeCheck(timeRange);
  }
}

export function getDateTimeFromDateFilter(
  dateFilter: Extract<FilterOption, { column_type: 'DATETIME' }>,
) {
  if (dateFilter.time_range === 'CUSTOM') return dateFilter;

  const maybeDateStrings = fromTimeRangeToClientDateStrings(
    dateFilter.time_range,
    dateFilter.first_day_of_week || 'MONDAY',
  );

  return {
    ...dateFilter,
    from_time: stringToUtcFormat(maybeDateStrings[0]),
    to_time: maybeDateStrings[1]
      ? stringToUtcFormat(maybeDateStrings[1])
      : stringToUtcFormat(maybeDateStrings[0]), // case time_range === 'YESTERDAY' | 'TODAY'
  };
}

export function getWeekParallelDateByPeriod(
  date: Dayjs,
  gapValue: number,
  period: 'year' | 'month' | 'quarter',
) {
  const pastStartOfMonth = date.subtract(gapValue, period).startOf(period);

  const dayDif = date.startOf(period).diff(pastStartOfMonth, 'day');

  const weekDif = Math.round(dayDif / 7);

  return date.subtract(weekDif, 'week');
}

export function getMonthlyRoundedDateRange({
  previousUnit,
  gapValue,
  currentEndDate,
  currentStartDate,
}: {
  previousUnit: 'month' | 'quarter' | 'year';
  gapValue: number;
  currentStartDate: Dayjs;
  currentEndDate: Dayjs;
}) {
  const rangeIsOverOrEqualOneMonth =
    currentStartDate.isBefore(currentEndDate.startOf('month')) ||
    currentStartDate.isSame(currentEndDate.startOf('month'), 'date');

  const endDateSameWithEndOfMonth = currentEndDate.isSame(
    currentEndDate.endOf('month'),
    'date',
  );
  const pastStartDate = currentStartDate.subtract(gapValue, previousUnit);
  const pastEndDate = currentEndDate.subtract(gapValue, previousUnit);

  if (rangeIsOverOrEqualOneMonth && endDateSameWithEndOfMonth) {
    return {
      pastStartDate: pastStartDate,
      pastEndDate: pastEndDate.endOf('month'),
    };
  }

  return { pastStartDate, pastEndDate };
}

export function getQuarterlyRoundedDateRange({
  previousUnit,
  gapValue,
  currentEndDate,
  currentStartDate,
}: {
  previousUnit: 'quarter' | 'year';
  gapValue: number;
  currentStartDate: Dayjs;
  currentEndDate: Dayjs;
}) {
  const rangeIsOverOrEqualOneMonth =
    currentStartDate.isBefore(currentEndDate.startOf('quarter')) ||
    currentStartDate.isSame(currentEndDate.startOf('quarter'), 'date');

  const endDateSameWithEndOfQuarter = currentEndDate.isSame(
    currentEndDate.endOf('quarter'),
    'date',
  );
  const pastStartDate = currentStartDate.subtract(gapValue, previousUnit);
  const pastEndDate = currentEndDate.subtract(gapValue, previousUnit);

  if (rangeIsOverOrEqualOneMonth && endDateSameWithEndOfQuarter) {
    return {
      pastStartDate: pastStartDate,
      pastEndDate: pastEndDate.endOf('quarter'),
    };
  }

  return { pastStartDate, pastEndDate };
}

export function fromC2pConfigToPastRange({
  c2pConfig,
  periodicity = 'DAILY',
  currentStartDate,
  currentEndDate,
  weekStart,
}: {
  c2pConfig: Exclude<ViewConfig['compare_with_past'], null | undefined>;
  periodicity?: TimeSeriesPeriod;
  currentStartDate: string;
  currentEndDate: string;
  weekStart: number;
}) {
  const startDate = dayjs(currentStartDate);
  const endDate = dayjs(currentEndDate);
  const fixedRange = endDate.diff(startDate);

  // Default value
  if (c2pConfig.period_time === 'RIGHT_BEFORE') {
    const fromDate = startDate
      .subtract(1, 'day')
      .subtract(fixedRange, 'milliseconds')
      .format(ISO_DATE_FORMAT);
    const toDate = getRoundUpEndDateFromCurrentDate({
      startDate: fromDate,
      currentRange: [currentStartDate, currentEndDate],
      periodicity,
      weekStart,
    });
    return {
      fromDate,
      toDate,
    };
  }

  if (c2pConfig.period_time === 'CUSTOM_DATE') {
    if (c2pConfig.from_date) {
      const fromDate = dayjs(c2pConfig.from_date).format(ISO_DATE_FORMAT);
      const toDate = getRoundUpEndDateFromCurrentDate({
        startDate: fromDate,
        currentRange: [currentStartDate, currentEndDate],
        periodicity,
        weekStart,
      });

      return {
        fromDate,
        toDate,
      };
    }

    const fromDate = startDate
      .subtract(1, 'day')
      .subtract(fixedRange, 'milliseconds')
      .format(ISO_DATE_FORMAT);
    const toDate = getRoundUpEndDateFromCurrentDate({
      startDate: fromDate,
      currentRange: [currentStartDate, currentEndDate],
      periodicity,
      weekStart,
    });

    return {
      fromDate,
      toDate,
    };
  }

  let pastStartDate = startDate;
  let pastEndDate = endDate;
  switch (c2pConfig.period_time) {
    case 'DAY': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'day');
      pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      break;
    }
    case 'WEEK': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'week');
      pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      break;
    }
    case 'MONTH': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'month');
      pastEndDate = endDate.subtract(c2pConfig.gap_value ?? 1, 'month');
      // NOTE: Modify the start & end date so that week 1 this month ~ week 1 previous month, etc...
      if (periodicity === 'WEEKLY') {
        pastStartDate = getWeekParallelDateByPeriod(
          startDate,
          c2pConfig.gap_value ?? 1,
          'month',
        );
        pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      }
      // NOTE: If full month
      if (periodicity === 'MONTHLY') {
        const {
          pastStartDate: monthlyRoundedStartDate,
          pastEndDate: monthlyRoundedEndDate,
        } = getMonthlyRoundedDateRange({
          previousUnit: 'month',
          gapValue: c2pConfig.gap_value ?? 1,
          currentStartDate: startDate,
          currentEndDate: endDate,
        });

        pastStartDate = monthlyRoundedStartDate;
        pastEndDate = monthlyRoundedEndDate;
      }

      break;
    }
    case 'QUARTER': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'quarter');
      pastEndDate = endDate.subtract(c2pConfig.gap_value ?? 1, 'quarter');

      if (periodicity === 'WEEKLY') {
        pastStartDate = getWeekParallelDateByPeriod(
          startDate,
          c2pConfig.gap_value ?? 1,
          'quarter',
        );
        pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      }

      if (periodicity === 'MONTHLY') {
        const {
          pastStartDate: monthlyRoundedStartDate,
          pastEndDate: monthlyRoundedEndDate,
        } = getMonthlyRoundedDateRange({
          previousUnit: 'quarter',
          gapValue: c2pConfig.gap_value ?? 1,
          currentStartDate: startDate,
          currentEndDate: endDate,
        });

        pastStartDate = monthlyRoundedStartDate;
        pastEndDate = monthlyRoundedEndDate;
      }

      if (periodicity === 'QUARTERLY') {
        const {
          pastStartDate: monthlyRoundedStartDate,
          pastEndDate: monthlyRoundedEndDate,
        } = getQuarterlyRoundedDateRange({
          previousUnit: 'quarter',
          gapValue: c2pConfig.gap_value ?? 1,
          currentStartDate: startDate,
          currentEndDate: endDate,
        });

        pastStartDate = monthlyRoundedStartDate;
        pastEndDate = monthlyRoundedEndDate;
      }
      break;
    }
    case 'YEAR': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'year');
      pastEndDate = endDate.subtract(c2pConfig.gap_value ?? 1, 'year');

      if (periodicity === 'WEEKLY') {
        pastStartDate = getWeekParallelDateByPeriod(
          startDate,
          c2pConfig.gap_value ?? 1,
          'year',
        );
        pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      }

      if (periodicity === 'MONTHLY') {
        const {
          pastStartDate: monthlyRoundedStartDate,
          pastEndDate: monthlyRoundedEndDate,
        } = getMonthlyRoundedDateRange({
          previousUnit: 'year',
          gapValue: c2pConfig.gap_value ?? 1,
          currentStartDate: startDate,
          currentEndDate: endDate,
        });

        pastStartDate = monthlyRoundedStartDate;
        pastEndDate = monthlyRoundedEndDate;
      }

      if (periodicity === 'QUARTERLY') {
        const {
          pastStartDate: monthlyRoundedStartDate,
          pastEndDate: monthlyRoundedEndDate,
        } = getQuarterlyRoundedDateRange({
          previousUnit: 'year',
          gapValue: c2pConfig.gap_value ?? 1,
          currentStartDate: startDate,
          currentEndDate: endDate,
        });

        pastStartDate = monthlyRoundedStartDate;
        pastEndDate = monthlyRoundedEndDate;
      }

      break;
    }
    default:
      throw new Error(`Invalid value of periodTime: ${c2pConfig}`);
  }

  return {
    fromDate: pastStartDate.format(ISO_DATE_FORMAT),
    toDate: pastEndDate.format(ISO_DATE_FORMAT),
  };
}

export function fromC2pConfigToPastRangeForBoard({
  c2pConfig,
  currentStartDate,
  currentEndDate,
}: {
  c2pConfig: Exclude<ViewConfig['compare_with_past'], null | undefined>;
  currentStartDate: string;
  currentEndDate: string;
}) {
  const startDate = dayjs(currentStartDate);
  const endDate = dayjs(currentEndDate);
  const fixedRange = endDate.diff(startDate);

  // Default value
  if (c2pConfig.period_time === 'RIGHT_BEFORE') {
    const pastEndDate = startDate.subtract(1, 'day');
    const pastStartDate = pastEndDate.subtract(fixedRange, 'milliseconds');

    return {
      fromDate: pastStartDate.format(ISO_DATE_FORMAT),
      toDate: pastEndDate.format(ISO_DATE_FORMAT),
    };
  }

  if (c2pConfig.period_time === 'CUSTOM_DATE') {
    if (c2pConfig.from_date) {
      const fromDate = dayjs(c2pConfig.from_date).format(ISO_DATE_FORMAT);
      const toDate = dayjs(c2pConfig.from_date)
        .add(fixedRange, 'millisecond')
        .format(ISO_DATE_FORMAT);

      return {
        fromDate,
        toDate,
      };
    }

    const pastEndDate = startDate.subtract(1, 'day');
    const pastStartDate = pastEndDate.subtract(fixedRange, 'milliseconds');

    return {
      fromDate: pastStartDate.format(ISO_DATE_FORMAT),
      toDate: pastEndDate.format(ISO_DATE_FORMAT),
    };
  }

  let pastStartDate = startDate;
  let pastEndDate = endDate;
  switch (c2pConfig.period_time) {
    case 'DAY': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'day');
      pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      break;
    }
    case 'WEEK': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'week');
      pastEndDate = pastStartDate.add(fixedRange, 'millisecond');
      break;
    }
    case 'MONTH': {
      // NOTE: If full month
      const {
        pastStartDate: monthlyRoundedStartDate,
        pastEndDate: monthlyRoundedEndDate,
      } = getMonthlyRoundedDateRange({
        previousUnit: 'month',
        gapValue: c2pConfig.gap_value ?? 1,
        currentStartDate: startDate,
        currentEndDate: endDate,
      });

      pastStartDate = monthlyRoundedStartDate;
      pastEndDate = monthlyRoundedEndDate;

      break;
    }
    case 'QUARTER': {
      const {
        pastStartDate: monthlyRoundedStartDate,
        pastEndDate: monthlyRoundedEndDate,
      } = getMonthlyRoundedDateRange({
        previousUnit: 'quarter',
        gapValue: c2pConfig.gap_value ?? 1,
        currentStartDate: startDate,
        currentEndDate: endDate,
      });

      pastStartDate = monthlyRoundedStartDate;
      pastEndDate = monthlyRoundedEndDate;
      break;
    }
    case 'YEAR': {
      pastStartDate = startDate.subtract(c2pConfig.gap_value ?? 1, 'year');
      pastEndDate = endDate.subtract(c2pConfig.gap_value ?? 1, 'year');

      const {
        pastStartDate: monthlyRoundedStartDate,
        pastEndDate: monthlyRoundedEndDate,
      } = getMonthlyRoundedDateRange({
        previousUnit: 'year',
        gapValue: c2pConfig.gap_value ?? 1,
        currentStartDate: startDate,
        currentEndDate: endDate,
      });

      pastStartDate = monthlyRoundedStartDate;
      pastEndDate = monthlyRoundedEndDate;

      break;
    }
    default:
      throw new Error(`Invalid value of periodTime: ${c2pConfig}`);
  }

  return {
    fromDate: pastStartDate.format(ISO_DATE_FORMAT),
    toDate: pastEndDate.format(ISO_DATE_FORMAT),
  };
}

export const getCustomSlicesFromDateRange = (
  startDateStr: string,
  endDateStr: string,
  periodicity: TimeSeriesPeriod,
  weekStart: number,
) => {
  const slices: number[] = [];
  const startDate = dayjs(startDateStr);
  const endDate = dayjs(endDateStr).locale({ ...enLocale, weekStart });

  switch (periodicity) {
    case 'DAILY': {
      return undefined;
    }
    case 'WEEKLY': {
      let currentDate = startDate.locale({ ...enLocale, weekStart });

      while (currentDate.isBefore(endDate)) {
        const nextPeriodEnd = currentDate.endOf('week');
        const periodLength = nextPeriodEnd.isBefore(endDate)
          ? nextPeriodEnd.diff(currentDate, 'day')
          : endDate.diff(currentDate, 'day');
        slices.push(periodLength + 1);
        currentDate = nextPeriodEnd.add(1, 'millisecond'); // Move to next period start
      }

      return slices;
    }
    case 'MONTHLY': {
      let currentDate = startDate;

      while (currentDate.isBefore(endDate)) {
        const nextPeriodEnd = currentDate.endOf('month');
        const periodLength = nextPeriodEnd.isBefore(endDate)
          ? nextPeriodEnd.diff(currentDate, 'day')
          : endDate.diff(currentDate, 'day');
        slices.push(periodLength + 1);
        currentDate = nextPeriodEnd.add(1, 'millisecond'); // Move to next period start
      }
      return slices;
    }
    case 'QUARTERLY': {
      let currentDate = startDate;

      while (currentDate.isBefore(endDate)) {
        const nextPeriodEnd = currentDate.endOf('quarter');
        const periodLength = nextPeriodEnd.isBefore(endDate)
          ? nextPeriodEnd.diff(currentDate, 'day')
          : endDate.diff(currentDate, 'day');
        slices.push(periodLength + 1);
        currentDate = nextPeriodEnd.add(1, 'millisecond'); // Move to next period start
      }
      return slices;
    }
    case 'YEARLY': {
      let currentDate = startDate;

      while (currentDate.isBefore(endDate)) {
        const nextPeriodEnd = currentDate.endOf('year');
        const periodLength = nextPeriodEnd.isBefore(endDate)
          ? nextPeriodEnd.diff(currentDate, 'day')
          : endDate.diff(currentDate, 'day');
        slices.push(periodLength + 1);
        currentDate = nextPeriodEnd.add(1, 'millisecond'); // Move to next period start
      }
      return slices;
    }
  }
};

export const getStartDatesByCustomSlices = (
  startDateStr: string,
  customSlices: number[],
) => {
  const dates: string[] = [];
  let startDate = dayjs(startDateStr);

  dates.push(startDate.format(ISO_DATE_FORMAT));

  customSlices.forEach((slice) => {
    startDate = startDate.add(slice, 'day');
    dates.push(startDate.format(ISO_DATE_FORMAT));
  });

  return dates;
};

export const getInRangeStartDate = (dateStr: string, rangeDates: string[]) => {
  const date = dayjs(dateStr);

  if (date.format(ISO_DATE_FORMAT) === rangeDates[0]) return rangeDates[0];
  if (date.format(ISO_DATE_FORMAT) === rangeDates[rangeDates.length - 1])
    return rangeDates[rangeDates.length - 1];

  const firstGreaterDateIndex = rangeDates.findIndex((item) => {
    const itemDate = dayjs(item);
    if (itemDate > date) return true;
    return false;
  });
  if (firstGreaterDateIndex === 0 || firstGreaterDateIndex === -1) return;
  return rangeDates[firstGreaterDateIndex - 1];
};

export const getDate = (date: string) => {
  return dayjs(date).format(ISO_DATE_FORMAT);
};

export const getWeekStartDate = (date: string, weekStart: number) => {
  const weekDay = dayjs(date)
    .locale({ ...enLocale, weekStart })
    .startOf('week');
  return weekDay.format(ISO_DATE_FORMAT);
};

export const getMonth = (date: string) => {
  return dayjs(date).format(MONTH_FORMAT);
};

export const getYear = (date: string) => {
  return dayjs(date).year().toString();
};

export const checkIfDate = (dateString: string) => {
  const maybeDateValue = dayjs(dateString);
  return maybeDateValue?.isValid() && maybeDateValue.get('y') > 1970
    ? true
    : false;
};
export function getWeekStart(firstDayOfWeek: DayOfWeek) {
  const weekStart = DAYS_OF_WEEK.indexOf(firstDayOfWeek);

  // NOTE: return 1 === 'MONDAY' when weekStart is invalid
  return weekStart < 0 ? 1 : weekStart;
}

export const getRoundUpEndDateFromCurrentDate = ({
  startDate,
  periodicity,
  currentRange,
  weekStart,
}: {
  startDate: string;
  periodicity: TimeSeriesPeriod;
  currentRange: [string, string];
  weekStart: number;
}) => {
  const [currentStartDate, currentEndDate] = currentRange;

  const curDateRanges = RANGED_DATES_FN_MAPPER[periodicity](
    dayjs(currentStartDate).format(ISO_DATE_FORMAT),
    dayjs(currentEndDate).format(ISO_DATE_FORMAT),
    weekStart,
  );

  const toDate = dayjs(startDate)
    .add(
      curDateRanges.length - 1,
      PERIOD_TO_DAYJS_UNIT_MAPPER[periodicity] as QUnitType,
    )
    .endOf(PERIOD_TO_DAYJS_UNIT_MAPPER[periodicity])
    .format(ISO_DATE_FORMAT);

  return toDate;
};
