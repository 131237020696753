import ohm from 'ohm-js';

import {
  ANALYTICAL_LIST,
  SUPPORTED_DATE_FNS_LIST,
  SUPPORTED_FNS_LIST,
} from '../constants';
import {
  AGG_FN_TYPE,
  ANALYTICAL_FN_TYPE,
  CONTAINS_OPERATOR,
  DATE_FN_TYPE,
  IF_EXPRESSION_TYPE,
  INDEX_EXPRESSION_TYPE,
  SPLIT_EXPRESSION_TYPE,
} from './constant';

const _listAggFNS = SUPPORTED_FNS_LIST.filter(
  (fn) => fn !== 'agg' && fn !== 'single_row_aggregate',
);

const _listAggSyntax = _listAggFNS.map((fn) => `"${fn}"`).join('|');

const _listDateSyntax = SUPPORTED_DATE_FNS_LIST.map((fn) => `"${fn}"`).join(
  '|',
);

const _listAnalyticalFNS = ANALYTICAL_LIST.map((fn) => `"${fn}"`).join('|');

export const OHM_RULES_FN = String.raw`
    FormulaFn {
      AddExp
        = AddExp "+" MulExp  -- plus
        | AddExp "-" MulExp  -- minus
        | MulExp

        MulExp
        = MulExp "*" PriExp  -- times
        | MulExp "/" PriExp  -- divide
        | PriExp
        
        PriExp 
        = "(" AddExp ")" -- paren
        | "+" PriExp   -- pos
        | "-" PriExp   -- neg
        | Expression
        
        Expression 
        = NormalExpression 
        | ${IF_EXPRESSION_TYPE} 
        | ${INDEX_EXPRESSION_TYPE}
        | ${SPLIT_EXPRESSION_TYPE}
        | ColumnName 
        | number
        | string
        | "true"
        | "false"
        | "null"
        | "undefined"
        
        ${IF_EXPRESSION_TYPE} = "if" "(" MulCondition ")" "then" PrimeCondition (ElseIf | Else)
        ElseIf = "elseif" "(" MulCondition ")" "then" PrimeCondition ElseIf* Else
        Else = "else" PrimeCondition
        MulCondition 
        = MulCondition "and" Condition --and
        | MulCondition "or" Condition --or
        | Condition
        Condition 
        = "(" MulCondition ")" -- paren
				| AddExp ComparisonOperator PrimeCondition --child
        PrimeCondition = AddExp | value
        ComparisonOperator = ">" | "<" | ">=" | "<=" | "=" | "!=" | "${CONTAINS_OPERATOR}"

        ${INDEX_EXPRESSION_TYPE} = "index" "(" Expression"," digit+ ")"
        ${SPLIT_EXPRESSION_TYPE} = "split" "(" Expression"," string ")"

        NormalExpression = Fn "(" Expression ")" 
        ColumnName = "{" (letter | digit | "_" | "(" | ")")* "}"
        ${AGG_FN_TYPE} = ${_listAggSyntax}
        ${DATE_FN_TYPE} = ${_listDateSyntax}
        ${ANALYTICAL_FN_TYPE} = ${_listAnalyticalFNS}
        Fn = ${ANALYTICAL_FN_TYPE} | ${AGG_FN_TYPE} | ${DATE_FN_TYPE}
        
        value = string | number
        string = "\"" (~"\"" any)* "\"" --doubleQuote
        | "'" (~"'" any)* "'" --singleQuote
        number
        = digit* "." digit+  -- fract
        | digit+  
    }
  `;
