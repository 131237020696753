import type { SxProps, Theme } from '@mui/material/styles';

import { AggregateType } from '@/models/view';

export const COMMON_CELL_SX = {
  whiteSpace: 'nowrap',
  padding: '12px 16px',
  bgcolor: 'neutralV2.8',
  borderRight: (t: Theme) => `1px solid ${t.palette.neutralV2[5]}`,
  borderBottom: (t: Theme) => `1px solid ${t.palette.neutralV2[5]}`,
};

export const COMMON_HEADER_CELL_SX = {
  cursor: 'default',
  textAlign: 'left',
  bgcolor: 'neutralV2.7',
  borderRight: (t: Theme) => `1px solid ${t.palette.neutralV2[5]}`,
  borderBottom: (t: Theme) => `1px solid ${t.palette.neutralV2[5]}`,
};

export const CELL_TEXT_OVERFLOWN_SX: SxProps<Theme> = {
  maxWidth: '100%',
  textOverflown: 'ellipsis',
  whiteSpace: 'nowrap',
};

// export const PARAMS_PER_PAGE_WHEN_GROUPED = Number.MAX_SAFE_INTEGER;
export const PARAMS_PER_PAGE_TABLE_NO_GROUPING = 200;
export const MAX_NUM_OF_RECORDS = 10_000_000;

export const AGGREGATED_TYPE_DICTIONARY: {
  [selectedFunctionName: string]: AggregateType | 'None';
} = {
  none: 'None',
  sum: 'SUM',
  avg: 'AVG',
  max: 'MAX',
  min: 'MIN',
  count: 'COUNT',
  count_distinct: 'COUNT_DISTINCT',
  count_empty: 'COUNT_EMPTY',
  count_not_empty: 'COUNT_NOT_EMPTY',
  formula: 'FORMULA',
};

export const AGGREGATED_BY_TEXT = {
  none: 'None',
  count: 'Count all',
  count_empty: 'Count empty',
  count_not_empty: 'Count not empty',
  count_distinct: 'Count distinct',
  first_item: 'First item',
};

export const AGGREGATED_BY_NUMBER_LABEL = {
  ...AGGREGATED_BY_TEXT,
  sum: 'Sum',
  avg: 'Average',
  max: 'Maximum',
  min: 'Minimum',
  formula: 'Formula',
  agg: 'AGG',
  single_row_aggregate: 'Single row aggregate',
  cummulate: 'Cummulate',
};

export const NONE_AGGREGATION_ITEM = { label: 'None', value: 'NONE' };

const COUNT_AGGREGATION_ITEM: {
  label: string;
  value: Lowercase<AggregateType>;
} = {
  label: 'Count all',
  value: 'count',
};

export const GROUPING_COLUMN_AGGREGATION_ITEMS = [
  // NONE_AGGREGATION_ITEM,
  COUNT_AGGREGATION_ITEM,
];

export const OPERATOR = {
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  EQUAL: 'eq',
  GREATER_OR_EQUAL: 'gte',
  LESS_OR_EQUAL: 'lte',

  CONTAIN: 'like',
  NOT_CONTAIN: 'not like',
  IS: 'in',
  IS_NOT: 'not in',
};

export const LOGICAL_OPERATOR = {
  AND: 'and',
  OR: 'or',
};

export const DATA_CELL_MARKER_ATTR_KEY = 'cigro-cell';
export const DATA_CELL_MARKER_TIME_SERIRES_ATTR_VALUE = 'ts';
export const DATA_CELL_MARKER_REGULAR_ATTR_VALUE = 'regular';
export const DATA_CELL_COLUMN_ORDER_ATTR_KEY = 'cigro-column-order';
