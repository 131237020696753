'use client';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  $createTextNode,
  $getSelection,
  $isTextNode,
  LexicalNode,
} from 'lexical';
import { createPortal } from 'react-dom';
import { TransitionGroup } from 'react-transition-group';

import { CIGRO_SCROLL_BAR_V1_MIXINS } from '@/constants/mixins';
import { CustomColumnsConfigMap } from '@/models/common';
import { DataModelColumn } from '@/models/data-model';
import { filterByProperties } from '@/utils/data';
import { spreadMuiSxes } from '@/utils/styles';

import {
  COLUMN_ID_REGEX,
  INDEX_FNS,
  SPLIT_FNS,
  TRIGGER_PROPERTY_NAME,
  USABLE_FNS,
} from '../../constants';
import { CONTAINS_OPERATOR } from '../../ohm-grammar/constant';
import { $createModelPropertyNode } from '../model-property-node';
import { useModelPropertyNodeRegister } from './ColumnParserPlugin';

const COLUMN_OPEN_SYMBOL = '{';
const FUNCTION_OPEN_SYMBOL = '(';
const FUNCTION_CLOSE_SYMBOL = ')';
const TRIGGER_SYMBOLS = [COLUMN_OPEN_SYMBOL, FUNCTION_OPEN_SYMBOL];

type SuggestionType = 'column' | 'function' | 'operator';

function isPunctuation(str: string): boolean {
  return /^[.,!?;:\-()[\]{}"'/\\@#$%^&*~\n]+$/.test(str);
}

class SuggestMenuOption extends MenuOption {
  type: SuggestionType;
  label: string;
  input: string;
  columnId: string;

  constructor(key: string, type: SuggestionType, label: string, input: string) {
    const _key = key + type; // avoid key duplication
    super(_key);
    this.type = type;
    this.label = label;
    this.input = input;
    this.columnId = key;
  }
}

const FORMULA_OPTIONS: SuggestMenuOption[] = USABLE_FNS.map(
  ({ fn }) =>
    new SuggestMenuOption(
      fn.toLowerCase(),
      'function',
      fn,
      fn.toLowerCase() + '(',
    ),
);

const ITEM_HEIGHT = 24;

const MAX_SHOWN_ITEMS = 6;

const SkeletonSuggestionList = () => (
  <Stack>
    <Typography>
      <Skeleton width="64px" />
    </Typography>
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Stack>
);

const SuggestionOption = ({
  type,
  label,
  onClick,
  isSelected,
}: // onMouseEnter,
{
  type: SuggestionType;
  label: React.ReactNode;
  isSelected: boolean;
  // onMouseEnter: () => void;
  onClick: () => void;
}) => {
  const elRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (!isSelected) return;
    elRef.current?.scrollIntoView();
  }, [isSelected]);

  return (
    <Stack
      ref={elRef}
      component="li"
      role="button"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={[
        {
          height: `${ITEM_HEIGHT}px`,
          p: '8px',
          cursor: 'pointer',
          boxShadow: (t) => `0 0 0 0.5px ${t.palette.neutralV2[5]}`,
        },
        isSelected ? { bgcolor: 'neutralV2.7' } : {},
      ]}
      onMouseEnter={(e) => {
        e.preventDefault();
        // onMouseEnter();
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      //
    >
      <Typography
        variant="subhead6"
        color={type === 'column' ? 'primary.100' : 'neutralV2.0'}
      >
        {label}
      </Typography>

      <Typography variant="subhead6" color="neutralV2.3">
        {type === 'column' ? '# Column name' : 'Function'}
      </Typography>
    </Stack>
  );
};

const NORMAL_AGG = 'normalAgg';

const SuggestionList: React.FC<{
  isLoading?: boolean | undefined;
  options: SuggestMenuOption[];
  selectedIndex: number | null;
  onSelectOption: (option: SuggestMenuOption) => void;
}> = ({ options, onSelectOption, isLoading = false, selectedIndex }) => {
  //
  if (isLoading) {
    return <SkeletonSuggestionList />;
  }

  if (!options.length) return null;

  return (
    <TransitionGroup>
      <Fade appear timeout={300}>
        <Stack
          className="cigro-suggestion-list"
          rowGap="8px"
          position="absolute"
          zIndex="modal"
          sx={{
            width: '341px',
            bgcolor: 'neutralV2.8',
            borderRadius: '4px',
            boxShadow: '2',
            border: '1px solid',
            borderColor: 'neutralV2.4',
          }}
          //
        >
          <Stack
            component="ul"
            gap={0}
            sx={[
              {
                pl: 0,
                m: 0,
                maxHeight: `${MAX_SHOWN_ITEMS * ITEM_HEIGHT}px`,
                minHeight: `${ITEM_HEIGHT}px`,
                borderRadius: '4px',
                overflowY: 'auto',
              },
              ...spreadMuiSxes(CIGRO_SCROLL_BAR_V1_MIXINS.sx),
            ]}
            //
          >
            {options.map((option, i) => {
              const { key, label, type } = option;

              const isSelected = i === selectedIndex;

              return (
                <SuggestionOption
                  key={key}
                  type={type}
                  label={label}
                  isSelected={isSelected}
                  onClick={() => {
                    onSelectOption(option);
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
      </Fade>
    </TransitionGroup>
  );
};

type Props = {
  columnsConfig: CustomColumnsConfigMap;
  modelColumns: DataModelColumn[];
};

export const SuggestionPlugin: React.FC<Props> = ({
  modelColumns: dataModelColumns = [],
  columnsConfig,
}) => {
  const [keyword, setKeyword] = useState<string | null>(null);

  // NOTE: check if node registered
  useModelPropertyNodeRegister();

  const filteredOptions = useMemo(() => {
    if (keyword == null) return [];

    let columnsOpts: SuggestMenuOption[] = [];

    if (dataModelColumns?.length) {
      // NOTE: nummeric column > other columns
      const sortedModelColumns = dataModelColumns
        .map((column) => ({
          ...column,
          alias: columnsConfig[column.column_name]?.alias || column.column_name,
        }))
        .sort((a, b) => {
          if (a.column_type === 'NUMBER' && b.column_type !== 'NUMBER')
            return -1;
          if (a.column_type !== 'NUMBER' && b.column_type === 'NUMBER')
            return 1;
          return 0;
        });

      const filteredByKeywordColumns = TRIGGER_SYMBOLS.includes(keyword)
        ? sortedModelColumns
        : filterByProperties(
            sortedModelColumns,
            ['column_name', 'alias'],
            keyword,
          );

      columnsOpts =
        filteredByKeywordColumns.map<SuggestMenuOption>(
          (column) =>
            new SuggestMenuOption(
              column.column_name,
              'column',
              column.alias || column.column_name,
              `{${column.column_name}}`,
            ),
        ) ?? [];
    }

    // NOTE: add contains operator
    if (CONTAINS_OPERATOR.includes(keyword)) {
      columnsOpts.push(
        new SuggestMenuOption(
          CONTAINS_OPERATOR,
          'operator',
          CONTAINS_OPERATOR,
          CONTAINS_OPERATOR,
        ),
      );
    }

    const opts = columnsOpts?.concat(
      filterByProperties(FORMULA_OPTIONS, ['key', 'label'], keyword),
    );

    return opts;
  }, [keyword, dataModelColumns, columnsConfig]);

  return (
    <>
      <LexicalTypeaheadMenuPlugin
        options={filteredOptions}
        onQueryChange={setKeyword}
        onSelectOption={(option, textNodeContainingQuery, closeMenu): void => {
          const insertedNode =
            option.type === 'column'
              ? $createModelPropertyNode(option.input, {
                  columnId: option.columnId,
                  alias: option.label,
                })
              : $createTextNode(option.input);

          // if no text node found
          // insert the content at caret position
          if (textNodeContainingQuery == null) {
            $getSelection()?.insertNodes([insertedNode]);
            return;
          }

          let replacedNode: LexicalNode = textNodeContainingQuery;
          const prevSiblingContent = textNodeContainingQuery
            .getPreviousSibling()
            ?.getTextContent();

          const content = replacedNode.getTextContent();
          const isTriggerSymbol = TRIGGER_SYMBOLS.find((symbol) =>
            content.endsWith(symbol),
          );

          // Handle auto fill params when choose column per function type
          let functionName = '';
          if (isTriggerSymbol === FUNCTION_OPEN_SYMBOL) {
            functionName = NORMAL_AGG;
            if (
              prevSiblingContent?.endsWith(SPLIT_FNS) ||
              prevSiblingContent?.endsWith(`${SPLIT_FNS}(`)
            ) {
              functionName = SPLIT_FNS;
            }
            if (
              prevSiblingContent?.endsWith(INDEX_FNS) ||
              prevSiblingContent?.endsWith(`${INDEX_FNS}(`)
            ) {
              functionName = INDEX_FNS;
            }
          }

          switch (functionName) {
            case SPLIT_FNS:
              replacedNode = replacedNode
                .insertAfter(insertedNode)
                .insertAfter($createTextNode(`, ""`))
                .insertAfter($createTextNode(FUNCTION_CLOSE_SYMBOL));
              break;
            case INDEX_FNS:
              replacedNode = replacedNode
                .insertAfter(insertedNode)
                .insertAfter($createTextNode(`, 1`))
                .insertAfter($createTextNode(FUNCTION_CLOSE_SYMBOL));
              break;
            case NORMAL_AGG:
              replacedNode = replacedNode
                .insertAfter(insertedNode)
                .insertAfter($createTextNode(FUNCTION_CLOSE_SYMBOL));
              break;
            default:
              // NOTE: remove last element trigger suggestion by suggestValue
              const arrayContent = content.split(' ');
              let lastElement = arrayContent.pop();
              while (lastElement === '') {
                lastElement = arrayContent.pop();
              }
              arrayContent.push(insertedNode.getTextContent());

              replacedNode = replacedNode.replace(
                $createTextNode(arrayContent.join(' ')),
              );
          }

          replacedNode.selectEnd();
          closeMenu();
          return;
        }}
        menuRenderFn={(
          el,
          { options, selectedIndex, selectOptionAndCleanUp },
          __,
        ) => {
          const list = (
            <SuggestionList
              isLoading={dataModelColumns == null}
              selectedIndex={selectedIndex}
              options={options}
              onSelectOption={selectOptionAndCleanUp}
            />
          );

          if (!el.current) return null;

          return createPortal(list, el.current);
        }}
        triggerFn={(text: string): MenuTextMatch | null => {
          // NOTE: disabled when start of string
          let countSingleQuote = text.split("'").length - 1;
          let countDoubleQuote = text.split('"').length - 1;

          if (
            countSingleQuote % 2 !== 0 ||
            countDoubleQuote % 2 !== 0 ||
            text.endsWith("'") ||
            text.endsWith('"')
          ) {
            return null;
          }

          // NOTE: when user press "{" -> show all available properties
          // if (text.endsWith(TRIGGER_SYMBOLS))
          const triggerAll = TRIGGER_SYMBOLS.find((symbol) =>
            text.endsWith(symbol),
          );

          if (triggerAll) {
            return {
              leadOffset: text.length,
              matchingString: triggerAll,
              replaceableString: triggerAll,
            };
          }

          // NOTE: property name
          const regex = new RegExp(`${TRIGGER_PROPERTY_NAME}`, 'i');
          const matches = regex.exec(text);

          if (matches) {
            const [_, maybePropertyName] = matches;

            const res = {
              leadOffset: Math.max(0, matches.index - 1), // NOTE: avoid negative leadOffset -> popover will be attached to document.body
              matchingString: maybePropertyName,
              replaceableString: '{' + maybePropertyName,
            };

            return res;
          }

          // NOTE: contains operator
          const lastStr = text.split(' ').pop();
          if (
            lastStr &&
            CONTAINS_OPERATOR.startsWith(lastStr) &&
            lastStr.length > 1 &&
            lastStr !== CONTAINS_OPERATOR
          ) {
            return {
              leadOffset: text.length - lastStr.length,
              matchingString: lastStr,
              replaceableString: lastStr,
            };
          }

          // NOTE: find a valid keyword
          const maybeKeywordRegEx = new RegExp(`${COLUMN_ID_REGEX}`, 'gi');

          const keywordMatches = Array.from(text.matchAll(maybeKeywordRegEx));

          const lastMatchArr = keywordMatches[keywordMatches.length - 1];

          if (!lastMatchArr?.[1]) return null;

          const lastKeyword = lastMatchArr[1];

          if (lastKeyword === '') return null;

          if (isPunctuation(lastKeyword)) return null;

          const leadOffset = text.length - lastKeyword.length;
          let replaceableString = lastKeyword;
          if (lastKeyword.startsWith(' ')) {
            replaceableString = lastKeyword.toString().trimStart();
          }

          const res = {
            leadOffset: leadOffset,
            matchingString: replaceableString,
            replaceableString,
          };

          return res;
        }}
      />
    </>
  );
};
